import { SortType, TableHeadCol } from "~/types/general";

export const useSetTableSorting = (
  tableCols: TableHeadCol[],
  col: TableHeadCol
) => {
  const titleIdx = tableCols.findIndex((t) => t.name === col.name);

  const sortTypeValues = Object.values(SortType);

  const currentSortTypeIdx = sortTypeValues.indexOf(col.type);

  let type;

  if (currentSortTypeIdx === sortTypeValues.length - 1) {
    type = sortTypeValues[0];
  } else {
    type = sortTypeValues[currentSortTypeIdx + 1];
  }

  tableCols.forEach((t) => {
    t.type = SortType.NO_SORT;
  });

  tableCols[titleIdx].type = type;

  const field = tableCols[titleIdx].field;

  return { "sort[]": `${field}-${type}` };
};
